$(document).on('turbolinks:load', ()=>{
  setSearchClearBtn();
  setSumTotalExpenses();
  setDestroyCheck();

  window.onDestroyNewExpenseDetail = onDestroyNewExpenseDetail;
  window.setSumTotalExpenses = setSumTotalExpenses;
})

function setSearchClearBtn(){
  $("#expenses-search-form .btn-clear").on('click', ()=>{
    _.each($("#expenses-search-form input"), (input)=>{
      input.value = "";
    })
    location.replace("/expenses_bills");
  })
}

function setSumTotalExpenses(){
  setTotalOnChange();
  $(".expenses-bill-detail-quantity, .expenses-bill-detail-unitary").on("change", ({ target })=>{    
    let tr = $(target).parents("tr");
    let quantity = tr.find(".expenses-bill-detail-quantity")[0].value;
    let unitary = tr.find(".expenses-bill-detail-unitary")[0].value;
    
    tr.find(".expenses-bill-detail-total")[0].value = quantity * unitary;

    setExpensesBillTotal();
  })
}

function setTotalOnChange(){
  $(".expenses-bill-detail-total").on("change", setExpensesBillTotal)
}

function setExpensesBillTotal(){
  let total = _.reduce($(".expenses-bill-detail-total"), (sum, n)=>{
    return sum + parseInt(n.value.replaceAll(".", ""));
  }, 0);
  
  $("#expenses_bill_total")[0].value = numberWithCommas(total);
}

function setDestroyCheck(){
  $(".destroy-check").on("click", ({ target })=>{
    let tr = $(target).parents("tr");
    if(tr.find(".expenses-bill-detail-id")[0].value == 0){
      onDestroyNewExpenseDetail({ target });
    }
  })
}

function onDestroyNewExpenseDetail({ target }){
  let tr = $(target).parents('tr')[0];
  $(tr).toggle(1000, ()=>{
    tr.remove();
  });
}

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}