import $ from 'jquery'

$(document).on("turbolinks:load", ()=>{
  $("#list-client-freights-btn").on("click", openReport)
  $("#client_freight_destination_code").on("change", searchDestinationByCode)
})

function openReport(){
  let url = window.location.href.replace("client_freights", "client_freights.pdf") 
  window.open(url);
}

function searchDestinationByCode({ target }){
  let code = target.value;
  $.ajax(`/address_book_entries.json?by_code=${code}`, {    
    method: 'GET',
    success: (response)=>{
      target.value = "";
      if (response.length > 0){
        let bookEntry = response[0];
        $("#client_freight_destination")[0].value = bookEntry.name;
        $("#client_freight_destination_city_id")[0].value = bookEntry.location_id;
        $("#client_freight_destination_address")[0].value = bookEntry.address;
        $("#client_freight_destination_number")[0].value = bookEntry.phone_number;
      } else {
        alertify.warning("No existe una dirección con ese código");
        $("#client_freight_destination")[0].value = "";
        $("#client_freight_destination_city_id")[0].value = "";
        $("#client_freight_destination_address")[0].value = "";
        $("#client_freight_destination_number")[0].value = "";
      }
    }
  })
}