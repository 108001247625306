// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "jquery"

import "@popperjs/core"
import "bootstrap"
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/tooltip';
import 'bootstrap-icons/font/bootstrap-icons.css'
import '@fortawesome/fontawesome-free/js/all'


import "../assets/main.scss"
import "../assets/login.scss"

import "../js/clients"
import "../js/freights"
import "../js/general"
import "../js/expenses"
import "../js/users"
import "../js/client_freights"


window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle.js')

Rails.start()
Turbolinks.start()
ActiveStorage.start()
