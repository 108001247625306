import $ from 'jquery'
import _ from 'lodash'
import '../assets/freights.scss'
import autocomplete from 'autocompleter';
import 'autocompleter/autocomplete.min.css'

//freight_freight_company_id
$( document ).on('turbolinks:load', function() {
  if($("#freights_form").length > 0){
    setCompanyListener();
    setNamesAutocomplete();
    setSearchButtons();
    // focus search input when the modal opens
    $("#freights-search-modal").on("shown.bs.modal", ()=>{
      $("#freights-search-input")[0].focus();
    })
    setSearchClient();
    setSearchClientBtn();
    setDescriptionComplete();
    setLocationAutocomplete();
  }
  if($("#form-report-freights").length > 0){
    setFormAction();
    setAllLocationsAction();
  }

});

function setSearchInput(type, modal){
  var timeout_running = false; 
  // obtengo el input
  var input = $("#freights-search-input");
  // limpio el keypress y lo reseteo
  input.off("keypress");
  input.on("keypress", ({ target })=>{
    // if it isn´t already searching, search
    if(!timeout_running) 
      timeout_running = true;
      // wait 1 sec to search, so it doesn't search for each key
      setTimeout(()=>{
        timeout_running = false;
        let search_term = input[0].value;
        $.ajax(
          `/freights/search/${search_term}`,
          { success: setModalTable(type, modal, false) }
        );
      }, 1000);
  })
}

function setSearchButtons(selector = null, type = null){
  if (selector == null){
    setSearchButtons("#btn-search-origin", "origin");
    setSearchButtons("#btn-search-destination", "destination");
  } else {
    let modal = new bootstrap.Modal($("#freights-search-modal"), {});
    $("#freights-search-input")[0].value = "";
    $(selector).on("click", ()=>{      
      setSearchInput(type, modal);
      $.ajax(
        "/freights/search",
        { success: setModalTable(type, modal) }
      );
    })
  }
}

function setModalTable(type, modal, execute_show = true){
  return (response)=>{
    $("#freights-table-container")[0].innerHTML = response;
    $("#freights-table-container tr").off("click");
    $("#freights-table-container tr").on("click", completeSearch(type, modal));
    if(execute_show) modal.show();
  }
}

function completeSearch(type, modal){
  return ({ target })=>{
    let tr = $(target).parents("tr")[0];
    
    $(`#freight_${type}`)[0].value = $(tr).find(".name")[0].innerText;
    $(`#freight_${type}_city`)[0].value = $(tr).find(".city")[0].innerText;
    $(`#freight_${type}_number`)[0].value = $(tr).find(".number")[0].innerText;
    //$(`#freight_${type}_address`)[0].value = $(tr).find(".address")[0].innerText;

    modal.hide();
  }
}

function setCompanyListener(){
  $("#freight_freight_company_id").on("change", ({ target }) => {
    console.log("company id", target.value);
    $.ajax({
      url: `/freight_companies/${target.value}`,
      dataType: "json",
      success: (resp) => {
        var commision_percentage = resp.commission_percentage;
        $("#freight_comission_percentage")[0].value = commision_percentage;
      }
    })
  })
}

function setNamesAutocomplete(){
  /*
  $("#freight_origin, #freight_destination").on("input", ({ target })=>{
    if(target.value.length > 1){
      lookForClient(target.value, (resp)=>{
        console.log(`busqueda ${target.value}`, resp);
      })
    }
  })*/
  _setAutocomplete("origin");
  _setAutocomplete("destination");
  setTabEventAutocomplete();

}

function _setAutocomplete(propertyName){
  autocomplete({
    onSelect: selectedAutocomplete(propertyName),
    input: document.getElementById(`freight_${propertyName}`),
    minLength: 2,
    emptyMsg: 'No se encuentran coincidencias',
    debounceWaitMs: 200,          
    className: `${propertyName}_autocomplete`,
    fetch: function(text, callback, trigger, cursorPos) {
        text = text.toLowerCase();
        // changes for kvs
        $.ajax({
          url: `/freights.json?by_${propertyName}_usage=${text}`,
          dataType: "json",
          success: (response)=>{        
            let list = _.map(response, (item)=>{ 
              return { label: item[propertyName], value: item }
            });
            callback(list);
          }
        })
    },
    preventSubmit: true
  });
}

function setTabEventAutocomplete(){
  $(document).keydown((e)=>{
    if(e.keyCode == 9 && (e.target.id == "freight_origin" || e.target.id == "freight_destination")) {
      let autocomplete = $(".autocomplete");
      
      if (autocomplete.length > 0 && $(".autocomplete .empty").length <= 0){
        e.preventDefault();
        let selecteds = $(".autocomplete .selected");
        if(selecteds.length > 0) {
          let value = selecteds[0].innerHTML;
          e.target.value = value;
          autocomplete.remove();
        }
      }
    }
  })
}

function selectedAutocomplete(type){
  return (item, input)=>{
    $(`#freight_${type}`)[0].value = item.value[type];
    /*if(item.value[`${type}_city`] !== undefined) 
      $(`#freight_${type}_city`)[0].value = item.value[`${type}_city`];*/
  }
}

function lookForClient(query, onSuccess){
  $.ajax({
    url: `/freights/search/${query}.json`,
    dataType: "json",
    success: onSuccess
  })
}

function setFormAction(){
  $("#form-report-freights").on('submit', (event)=>{
    event.preventDefault();
    let { target } = event;
    let type = "";
    let params = [];
    let locations = [];
    let payment_types = [];
    let excel = false;
    $(target).find('input, select').each((index, input)=>{
      // if is the authenticity_token
      if(input.name == "authenticity_token" || input.name == "commit")
        return;
      // if a radio button is not checked
      if(input.type == "radio" && !input.checked)
        return;        
      if(input.id == "all_locations")
        return;
      if(input.type == "checkbox" && input.name == "by_location[]"){
        if(input.checked) locations.push(input.value);
        return;
      }
      if(input.name == "payment_type[]"){        
        if(input.checked) payment_types.push(input.value);
        return;
      }
      if(input.name == "is_income"){
        switch(input.value) {
          case "0":
            type = "outcomes"
            break;
          case "1":
            type = "incomes"
            break;
          case "2":
            type = "multienvios"
            break;
          case "4":
            type = "todos"
            excel = true;
            break;
          case "5":
            type = "incomes"
            excel = true;
            break;
          case "6":
            type = "outcomes"
            excel = true;
            break;
          case "7":
            type = "receipt"
            break;
          default:
            type = "todos"
            
        }
      } 
      // else, if not empty
      if(input.value != "")
        params.push(`${input.name}=${input.value}`);
      
    })

    setTimeout(
      ()=>{ $('input[type=submit], form').prop('disabled', false); },
      1500
    )

    if(locations.length > 0){
      params.push(`by_location=${locations.join(',')}`);
    }
    if(payment_types.length > 0){
      params.push(`by_payment_type=${payment_types.join(',')}`);
    }


    let url = `/freights/reports/${type}.pdf?${params.join("&")}`;   
    if(excel){ 
      url = `/freights/reports/${type}.xlsx?${params.join("&")}`;   
    }
    window.open(url);
  })
}

function setAllLocationsAction(){
  $("#all_locations").on("change", ({ target })=>{
    _.each($("input[name='by_location[]']"), (ele)=>{
      ele.checked = target.checked;
    })
  })
}

/* For index */
$( document ).on('turbolinks:load', function() {
  if($("#freights-index").length > 0){
    $("#render_pdf_btn").on("click", ()=>{
      let current_url = window.location.href;
      if(!current_url.includes("freights")){
        current_url = "/freights.pdf"
      } else {
        current_url = current_url.replace("freights", "freights.pdf");
      }
      window.open(current_url, '_blank');
    })
  }  
});

function setSearchClient(){
  let freight_client_id = $("#freight_client_id");
  freight_client_id.on("change", searchClientByCode);
  $("#client_ruc").on("change", searchClientByRuc);
  if(freight_client_id[0].value !== "") {
    searchClientByCode({ target: freight_client_id[0] });
  }
}

function searchClientByCode({ target }){
  let code = target.value;
  $.ajax(`/clients/${code}.json`, {
    success: setClient,
    error: errorClient
  })  
}

function searchClientByRuc({ target }){
  let ruc = target.value;
  $.ajax('/clients.json', {
    data: {
      by_ruc: ruc
    },
    success: setClient,
    error: errorClient
  })
}

function setClient(client){
  if (Array.isArray(client)){
    if(client.length <= 0) {
      errorClient({ code: 404 })
      return;
    }
    client = client[0];
  }
  $("#client-error-span")[0].innerText = "";
  $("#freight_client_id")[0].value = client.id;
  $("#client_ruc")[0].value = client.ruc;
  $("#client_dv")[0].value = client.dv;
  $("#client_name")[0].value = getName(client);
}

function getName(client) {
  let client_name = client.names[0];
  if (client_name == null) { return "sin Nombre" }
  _.each(client.names, (current_name)=>{
    if (current_name.principal){
      return current_name.name;
    }
  })
  return client_name.name;
}

function errorClient(error){
  _.each($("#client_ruc, #client_name, #client_dv, #freight_client_id"), (ele)=>{
    ele.value = "";
  })
  let message = "Error al conectar con el servidor";
  if (error.code == 404) message = "No se encontró el mensaje especificado";
  $("#client-error-span")[0].innerText = message;
}

function setSearchClientBtn(){
  $("#search-client").on("click", ()=>{
    $.ajax("/clients/search", {
      success: openClientModal
    })
  });
}

function openClientModal(clients_table){  
  window.clientModal = new bootstrap.Modal("#clients-search-modal");
  $("#clients-table-container")[0].innerHTML = clients_table;
  setSelectClientButton();
  setSearchBarListener();
  clientModal.show();
}

function setSearchBarListener(){
  $("#clients-search-input").on("keyup", delay(({ target })=>{
    let q = target.value;
    $.ajax("/clients.json", {
      data: { q },
      success: updateClientsTable
    })    


  }, 500));
}

function updateClientsTable(response){
  console.log("response", response);
  let rows = _.map(response, (row)=>{
    let name = "Sin nombre";
    if(row.names.length > 0){
      name = row.names[0].name;
    }
    return `
    <tr>
      <td class="client-id-td">${row.id}</td>
      <td>${name}</td>
      <td>${row.ruc}-${row.dv}</td>
      <td><button id="select-client-btn" type="button" class="btn btn-primary select-client-btn">Seleccionar</button></td>
    </tr>
    `;
  })
  $("#clients-table-container tbody")[0].innerHTML = rows.join("");
  setSelectClientButton();
}

function setSelectClientButton(){ 
  console.log("setted client btn"); 
  $("#select-client-btn, .select-client-btn").on("click", ({ target })=>{
    console.log("found");
    let tr = $($(target).parents("tr")[0]);
    let id = tr.find(".client-id-td")[0].innerText;
    $.ajax(`/clients/${id}.json`, {
      success: setClient,
      error: errorClient
    })  
    clientModal.hide();
  });
}

function delay(fn, ms) {
  let timer = 0
  return function(...args) {
    clearTimeout(timer)
    timer = setTimeout(fn.bind(this, ...args), ms || 0)
  }
}

function setDescriptionComplete(){
  $("#description_select").on("change", ({ target })=>{
    $("#freight_description")[0].value = target.value;
  })
}
 
function setLocationAutocomplete(){
  $("#freight_location_id").on("change", ({ target })=>{
    $("#freight_destination_city")[0].value = target.selectedOptions[0].innerText;
  })
}
