export function calculateDv(ruc){
  let basemax = 11;
  let sum = 0;
  let factor = 2;
  let rev_num = (ruc+"").split("").reverse();

  rev_num.forEach(char => {    
    factor = factor > basemax ? 2 : factor;
    sum += factor * parseInt(char);
    factor += 1;
  });

  let rest = sum % 11;
  return rest > 1 ? 11 - rest : 0;
}

export function isNumeric(str) {
  if (typeof str != "string") return false // we only process strings!  
  return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
         !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}