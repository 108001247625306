import $ from "jquery";
import _ from "lodash";
import { calculateDv } from './utils';

$( document ).on('turbolinks:load', function() {
  if($("#clients_form").length > 0){
    console.log("Loading clients functions");
    $("#add_name_btn").on("click", addClientNameRow);
    $("#add_address_btn").on("click", addAddressRow);
    $("#add_phone_number_btn").on("click", addPhoneNumberRow);
    setNameAutocomplete();
  }
  setClientReport();
})

function setClientReport(){
  $("#generate_reports_form").on("submit", (e)=>{
    e.preventDefault();
    let params = [];
    _.each($("#generate_reports_form input"), (input)=>{
      if(input.type == "checkbox" && input.name == "credit"){
        if(
          (input.value == "0") || (input.value == "1" && !input.checked)
        ){
          return;
        }
      }
      params.push(`${input.name}=${input.value}`);
    });
    window.open(`/freights.pdf?${params.join("&")}`);
  });
}

function setNameAutocomplete(){
  $("#client_ruc").on("change", ({target})=>{
    let ruc = target.value;
    $.ajax("https://server.tamaysistemas.com/consulta-ruc/", {
      data: { ruc },
      success: autocompleteName
    });
    let dv = calculateDv(ruc);
    $("#client_dv")[0].value = dv; 
  })
}

function autocompleteName(response){
  console.log("ruc response is", response);
  let name_input = $("#add_name_input")[0]
  name_input.value = response.name.replace("\\", "").replace("\"", "");
  $("#add_name_btn").click();
  name_input.value = "";
  
}

function addClientNameRow(){
  var row_number = $("#names-container tbody tr").length;
  var new_name = $("#add_name_input")[0].value;
  var inner_text = 
    `<tr>
      <td>
        <input 
          class="form-control" 
          placeholder="Nombre" 
          type="text" 
          name="client[client_names_attributes][${row_number}][name]" 
          id="client_client_names_attributes_1_name"
          value="${new_name}">
      </td>
      <td>
        <div class="form-check form-switch">
          <input name="client[client_names_attributes][${row_number}][principal]" type="hidden" value="${row_number <= 0 ? 1 : 0}">
          <input class="form-check-input" type="checkbox" value="1" name="client[client_names_attributes][${row_number}][principal]" id="client_client_names_attributes_${row_number}_principal">
          <label class="form-check-label" for="client_client_names_attributes_${row_number}_principal">Si</label>
        </div>
      </td>
      <td>
        <button class="btn btn-danger delete-row-btn" type="button"><i class="fas fa-trash"></i></button>
      </td>
    </tr>`;

  $($("#names-container tbody"))[0].innerHTML += inner_text;
  setDeleteRowBtns();
}

function addAddressRow(){
  var row_number = $("#addresses-container tbody tr").length;
  var inner_text = `
  <tr>
    <td>
      <input class="form-control" placeholder="Descripción" type="text" name="client[addresses_attributes][${row_number}][description]" id="client_addresses_attributes_${row_number}_description">
      <textarea class="form-control mt-2" placeholder="Contenido" name="client[addresses_attributes][${row_number}][content]" id="client_addresses_attributes_${row_number}_content"></textarea>
    </td>
    <td>
      <button class="btn btn-danger delete-row-btn" type="button"><i class="fas fa-trash"></i></button>
    </td>
  </tr>
  `;
  $($("#addresses-container tbody"))[0].innerHTML  += inner_text;
  setDeleteRowBtns();
}

function addPhoneNumberRow() {
  var row_number = $("#phone-numbers-container tbody tr").length;
  var inner_text = `
    <tr>
      <td>
        <input class="form-control" 
          placeholder="Descripción" 
          type="text" 
          name="client[phone_numbers_attributes][${row_number}][description]" 
          id="client_phone_numbers_attributes_${row_number}_description">
      </td>
      <td>
        <input class="form-control" 
          placeholder="Número" 
          type="text" 
          name="client[phone_numbers_attributes][${row_number}][full_number]" 
          id="client_phone_numbers_attributes_${row_number}_full_number">
      </td>
      <td>
        <button class="btn btn-danger delete-row-btn" type="button"><i class="fas fa-trash"></i></button>
      </td>
    </tr>
  `;
  $($("#phone-numbers-container tbody"))[0].innerHTML += inner_text;
  setDeleteRowBtns();
}

function setDeleteRowBtns(){
  $(".delete-row-btn").on("click", ({ target })=>{
    //console.log("click");
    
    $(target).parents("tr")[0].remove();
  })
}